<template>
  <b-row class="justify-content-center">
    <b-card>
      <validation-observer
        ref="infoRules"
        tag="form"
      >
        <b-row>
          <b-col md="12">
            <validation-provider
              name="languages"
              rules="required"
            >
              <b-form-group
                label-for="languages"
              >
                <label class="selectLanguage"> Plese Select the Language You want to show data </label>
                <v-select
                  id="languages"
                  v-model="code"
                  label="name"
                  required
                  :reduce="lang => lang.code"
                  :options="languagesList"
                  class="select-size-lg"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="code">
          <b-col md="6">
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Facebook"
                label-for="title_head"
              >
                <b-form-input
                  id="title_head"
                  v-model="editAboutPageData.facebook"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Facebook"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Twitter"
                label-for="twitter"
              >
                <b-form-input
                  id="twitter"
                  v-model="editAboutPageData.twitter"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Twitter"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="LinkedIn"
                label-for="linked"
              >
                <b-form-input
                  id="linked"
                  v-model="editAboutPageData.linkedin"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="LinkedIn"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Instagram"
                label-for="insta"
              >
                <b-form-input
                  id="insta"
                  v-model="editAboutPageData.instagram"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Instagram"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Footer Description"
                label-for="desc"
              >
                <b-form-textarea
                  id="desc"
                  v-model="editAboutPageData.description"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Footer Description"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6" />
          <b-col md="6">
            <b-form-group
              label="Footer Logo"
            >
              <b-form-file
                accept=".jpg, .png"
                placeholder="Choose file"
                multiple
                @change="onChangePageHeadImage"
              />
              <div
                v-if="preview"
                class="mt-2"
              >
                <img
                  :src="preview"
                  class="img-fluid"
                  width="100"
                >
              </div>
              <div
                v-else
                class="mt-2"
              >
                <img
                  :src="editAboutPageData.logo"
                  class="img-fluid"
                  width="100"
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="FCI Logo"
            >
              <b-form-file
                accept=".jpg, .png"
                placeholder="Choose file"
                multiple
                @change="onChangeFile"
              />
              <div
                v-if="previewKey"
                class="mt-2"
              >
                <img
                  :src="previewKey"
                  class="img-fluid"
                  width="100"
                >
              </div>
              <div
                v-else
                class="mt-2"
              >
                <img
                  :src="editAboutPageData.image"
                  class="img-fluid"
                  width="100"
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="code">
          <b-col md="6">
            <validation-provider
              name="languages"
              rules="required"
            >
              <b-form-group
                label-for="languages"
              >
                <label class="selectLanguage"> Plese Select The language in which you want to add this information </label>
                <v-select
                  id="languages"
                  v-model="editAboutPageData.language_id"
                  label="name"
                  required
                  :reduce="lang => lang.id"
                  :options="languagesList"
                  class="select-size-lg"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-col
          v-if="code"
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="addAboutPAgeData()"
          >
            Save
          </b-button>
        </b-col>
      </validation-observer>
    </b-card>
  </b-row>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */

import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted, watchEffect,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BImg, BFormFile, BCardText, BMediaBody, BMediaAside,
  BDropdown, BDropdownItem, BPagination, BFormGroup, BFormInvalidFeedback, BFormTextarea, BFormSelect, VBModal, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import axios from 'axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import store from '@/store'
import selectLanguage from '@/common/components/SelectLanguage.vue'
import footer from '@/views/footer/footer'
import footerModule from './footerModule'

Vue.use(VueSweetalert2)
Vue.use(VueFileAgent)

export default {
  components: {
    BCard,
    vSelect,
    selectLanguage,
    BMediaAside,
    BCardText,
    BMediaBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BForm,
    quillEditor,
    VueFileAgentStyles,
  },
  data() {
    return {
      // editAboutPageData: {
      //   facebook: '',
      //   instagram: '',
      //   twitter: '',
      //   linkedin: '',
      //   description: '',
      //   logo: '',
      //   image: '',
      // },
      preview: null,
      image: null,
      previewNotified: null,
      imageNotified: null,
      previewKey: null,
      imageKey: null,
      fileRecords: [],
      fileRecordsForUpload: [],
      languagesList: [],
    }
  },
  setup() {
    const FOOTER_MODULE = 'footerModule'

    // Register module
    if (!store.hasModule(FOOTER_MODULE)) store.registerModule(FOOTER_MODULE, footerModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FOOTER_MODULE)) store.unregisterModule(FOOTER_MODULE)
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const code = ref('')

    watchEffect(() => store.dispatch('footerModule/getAboutPageData', { code: code.value }))

    const editAboutPageData = computed(() => store.state.footerModule.subclassifications)

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      editAboutPageData,
      code,
    }
  },
  created() {
    this.getAboutPageData()
    this.getLanguages()
  },
  methods: {
    async getLanguages() {
      const response = await axios.get('get/language')
      this.languagesList = response.data.success
    },
    onChangePageHeadImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.editAboutPageData.logo = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeFile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.editAboutPageData.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.previewKey = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.imageKey = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    getAboutPageData() {
      axios.get('get/footer').then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.editAboutPageData = res.data.success
      })
    },
    addAboutPAgeData() {
      const formData = new FormData()
      formData.append('facebook', this.editAboutPageData.facebook)
      formData.append('instagram', this.editAboutPageData.instagram)
      formData.append('linkedin', this.editAboutPageData.linkedin)
      formData.append('twitter', this.editAboutPageData.twitter)
      formData.append('description', this.editAboutPageData.description)
      formData.append('logo', this.editAboutPageData.logo)
      formData.append('image', this.editAboutPageData.image)
      formData.append('language_id', this.editAboutPageData.language_id)

      axios.post('add/footer', formData).then(res => {
        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).catch(error => {
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Somthing Went Wrong',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
